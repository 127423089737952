import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';

import RichText from '~/shared/components/RichText';
import * as full from '~/shared/components/RichText/configs/full';
import { richTextPropType } from '~/shared/util/shared-prop-types';
import {
  pageWidthColProps,
  storylanePageWidthColProps,
} from '~/shared/styles/helpers';
import dataSelector from '~/shared/util/data-selector';
import * as Url from '~/shared/services/url';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const StyledCol = styled(Col)`
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

/**
 * A rich text section.
 */
function LongText({ content = {}, className = '', contentType, name, id }) {
  const { featureToggles } = useOptimizelyData();
  const isStorylaneContent = useMemo(() => {
    const allowedHosts = ['app.storylane.io'];
    if (!content?.content?.length) {
      return false;
    }

    return content?.content?.some(
      (entry) =>
        entry?.nodeType === 'embedded-entry-block' &&
        allowedHosts.includes(Url.parse(entry?.data?.target?.src).host),
    );
  }, [content]);

  if (isEmpty(content)) {
    return null;
  }

  // Use the appropriate ratio based on the content
  const widthColProps = isStorylaneContent
    ? storylanePageWidthColProps
    : pageWidthColProps;

  return (
    <Grid
      className={className}
      data-selector={dataSelector('section', 'long_text')}
      data-elbcontext="component:long_text"
      role="region"
    >
      <Row>
        <StyledCol {...widthColProps}>
          <RichText
            richText={content}
            renderNode={full.createRenderNode(
              {
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              },
              featureToggles,
            )}
            renderMark={full.renderMark}
          />
        </StyledCol>
      </Row>
    </Grid>
  );
}

LongText.propTypes = {
  content: richTextPropType,
  className: PropTypes.string,
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

/**
 * @component
 */
export default LongText;
